html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: "OpenSans", sans-serif;
    background-color: #fff;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    transition: all .3s;
}
